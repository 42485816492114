import { useState, useEffect } from "react";
import "./style.css";
import Page from "../../components/Page";
import Loading from "../../components/Loading";
import api, { MENSAGEM_ERRO_HTTP } from "../../services/api";
import { IoPricetag, IoLocationSharp, IoPerson, IoInformationCircle  } from "react-icons/io5";
import { BiSolidCopy } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa6";
import { PiNotepadFill } from "react-icons/pi";
import TelaGeral from "../../components/TelaGeral";
import PanelCollapse from "../../components/PanelCollapse";


const Item = ({ item, handleFiltraDetalhe }) => {
    const size = 18;
    const color = "slategray";

    function handleMaps() {
        if (item?.endereco)
            window.open(item?.endereco);
    }

    return (
        <div className="home-list-item" style={{backgroundColor: item.outro === "S" ? "rgba(211, 211, 211, 0.6)" : "#FFF"}}>
            <span className="home-list-hour">
                {item.hora}
            </span>

            <div className="home-list-detail">
                <IoPricetag size={size} color={color} />
                <span style={{fontWeight: "bold", fontSize: 18, cursor: "pointer", textDecoration: "underline"}} onClick={(() => {handleFiltraDetalhe(item.os)})}>
                    {item.os}
                </span>

                <span style={{display: "flex", marginLeft: "10px", gap: "3px", alignItems: "center"}}>
                    <FaUserTie size={size} color={color} />
                    <span>
                        {item.responsavel}
                    </span>

                    {item?.auxiliar
                    ? <span> / {item.auxiliar}</span>                    
                    : null
                    }
                </span>                
            </div>

            <div className="home-list-detail">
                <IoPerson size={size} color={color} />
                <span>{item.cliente}</span>
            </div>    

            <div className="home-list-detail" style={{cursor: "pointer", textDecoration: "underline", color: "#b72126", fontWeight: "bold"}} onClick={handleMaps}>
                <IoLocationSharp size={size} color={color} />
                <span>{item.cidade}</span>
            </div>                

            <div className="home-list-detail">
                <PiNotepadFill size={size} color={color} />
                <span>{item.titulo}</span>
            </div>

            <div className="home-list-detail" style={{fontWeight: "bold"}}>
                <IoInformationCircle size={size} color={color} />
                <span>{item.obs}</span>
            </div>
        </div>
    );
}

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [daysList, setDaysList] = useState([]);
    const [day, setDay] = useState(0);
    const [data, setData] = useState([]);
    const [detailShow, setDetailShow] = useState(false);
    const [detailData, setDetailData] = useState({});

    function toggleDay(index) {
        setDay(index);
    }

    function handleFiltraAgenda() {
        setLoading(true);
        setData([]);

        api.get("/agenda-servico/lista", {params:{dia: day}})
            .then(response => response.data)
            .then(response => {
                if (! response.error)
                    setData(response.list);
            })
            .catch(() => MENSAGEM_ERRO_HTTP())
            .finally(() => setLoading(false));
    }

    function handleFiltraDetalhe(os) {
        setDetailData({});
        setDetailShow(true);

        api.get("/agenda-servico/detalhe", {params:{os: os}})
            .then(response => response.data)
            .then(response => {
                if (! response.error)
                setDetailData(response);
            })
            .catch(() => null)
            .finally(() => null);
    }    

    useEffect(() => {
        handleFiltraAgenda();
    }, [day]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("login"));
        let now = new Date();
        let day;
        let week;
        let i = 0;
        let temp = [];
        let diasAgendaAnterior = 0;
        let diasAgendaPosterior = 3;

        if (user && user?.diasAgendaAnterior)
            diasAgendaAnterior = user.diasAgendaAnterior;

        if (user && user?.diasAgendaPosterior)
            diasAgendaPosterior = user.diasAgendaPosterior;

        // Dias anteriores
        now.setDate(now.getDate() - diasAgendaAnterior);
        for (i = 0; i < diasAgendaAnterior; i++) {
            day = now.toLocaleString("pr-BR", {day: "2-digit"});
            week = now.toLocaleString("pr-BR", {weekday: "long"}); 
            week = week.toUpperCase().substring(0, 3);
            temp.push({
                "description": `${day} | ${week}`,
                "index": i - diasAgendaAnterior,
                "divider": i - diasAgendaAnterior === -1
            });  
            now.setDate(now.getDate() + 1);
        }        

        // Dia de hoje
        day = now.toLocaleString("pr-BR", {day: "2-digit"});
        week = now.toLocaleString("pr-BR", {weekday: "long"}); 
        week = week.toUpperCase().substring(0, 3);
        temp.push({
            "description": `${day} | ${week}`,
            "index": i - diasAgendaAnterior,
            "divider": true
        });   
        now.setDate(now.getDate() + 1);  
        
        // Dias posteriores
        for (let i = 1; i < diasAgendaPosterior; i++) {
            day = now.toLocaleString("pr-BR", {day: "2-digit"});
            week = now.toLocaleString("pr-BR", {weekday: "long"}); 
            week = week.toUpperCase().substring(0, 3);
            temp.push({
                "description": `${day} | ${week}`,
                "index": i,
                "divider": false
            });  
            now.setDate(now.getDate() + 1);
        }    

        setDaysList(temp);
    }, []);

    return (
        <Page>
            <div className="home-container">
                <TelaGeral
                    isOpen={detailShow}
                    onRequestClose={() => setDetailShow(false)}
                    titulo="Detalhes"
                >
                    <div className="home-os-modal-content">
                        <div className="home-os-modal-details">
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">OS</span>
                                <span className="home-os-modal-detail-valor">{detailData?.os}</span>
                            </div>   
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">Data execução</span>
                                <span className="home-os-modal-detail-valor">{detailData?.dataSolicitacao} {detailData?.horaSolicitacao}</span>
                            </div>                                               
                        </div>

                        <div className="home-os-modal-details">
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">Endereço</span>
                                <span className="home-os-modal-detail-valor">{detailData?.endereco ? detailData?.endereco : "NÃO INFORMADO"}</span>
                            </div>  
                        </div>                        

                        <div className="home-os-modal-details">
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">Solicitante</span>
                                <span className="home-os-modal-detail-valor">{detailData?.solicitante}</span>
                            </div>  
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">Urgente</span>
                                <span className="home-os-modal-detail-valor">{detailData?.urgente ? "SIM" : "NÃO"}</span>
                            </div>                                              
                        </div> 

                        <div className="home-os-modal-details">
                            <div className="home-os-modal-detail">
                                <span className="home-os-modal-detail-titulo">Descrição</span>
                                <span className="home-os-modal-detail-valor">{detailData?.titulo}</span>
                            </div>  
                        </div>

                        <div className="home-os-modal-details">
                            <PanelCollapse titulo="Solicitação">
                                <div className="home-os-modal-detail">
                                    {detailData?.solicitacao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                        return <span key={key} className="home-os-modal-detail-valor">{item}<br/></span>
                                    })}
                                </div>
                            </PanelCollapse>
                        </div>

                        <div className="home-os-modal-details">
                            <PanelCollapse titulo="Problema" open={false}>
                                <div className="home-os-modal-detail">
                                    {detailData?.problema?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                        return <span key={key} className="home-os-modal-detail-valor">{item}<br/></span>
                                    })}
                                </div>
                            </PanelCollapse>
                        </div>     

                        <div className="home-os-modal-details">
                            <PanelCollapse titulo="Serviço executado" open={false}>
                                <div className="home-os-modal-detail">
                                    {detailData?.solucao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                        return <span key={key} className="home-os-modal-detail-valor">{item}<br/></span>
                                    })}
                                </div>
                            </PanelCollapse>
                        </div>  

                        <div className="home-os-modal-details">
                            <PanelCollapse titulo="Observações" open={false}>
                                <div className="home-os-modal-detail">
                                    {detailData?.observacao?.replace(/\\n/g, '\n').split('\n').map((item, key) => {
                                        return <span key={key} className="home-os-modal-detail-valor">{item}<br/></span>
                                    })}
                                </div>
                            </PanelCollapse>
                        </div>                                                                                            
                    </div>                   
                </TelaGeral>

                <div className="home-filter">
                    <span>Agenda de atendimentos ({data.length})</span>

                    <div className="home-day-filter">
                        {
                            daysList?.map((date, index) => {
                                return (
                                    <>
                                        <span 
                                            className={day === date.index ? "home-day-filter-button-pressed" : "home-day-filter-button"}
                                            key={index}
                                            onClick={() => toggleDay(date.index)}
                                        >
                                            {date.description}
                                        </span>
                                        {date.divider ? <div className="home-day-filter-divider"></div> : null}
                                    </>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="home-list">
                    {
                        loading
                        ? <div className="page-flex-1-center"><Loading size={42} /></div>
                        : null
                    }

                    {
                        data?.map((item) => {
                            return <Item key={item.os} item={item} handleFiltraDetalhe={handleFiltraDetalhe} />
                        })
                    }
                </div>
            </div>
        </Page>
    );
};

export default Home;